import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4×6\\@75% 1RM`}</p>
    <p>{`Pendlay Rows 4×6\\@75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 20 S2OH (135/95)(RX+ 155/105)`}</p>
    <p>{`3 Rounds of,`}</p>
    <p>{`3-Rope Climbs to 15′`}</p>
    <p>{`15-HSPU’s`}</p>
    <p>{`Cash Out: 20 S2OH`}</p>
    <p>{`For time.`}</p>
    <p>{`*`}{`compare to 10/16/20`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Open workout 21.2 will be released live at 8:00pm tonight
at Games.CrossFit.com!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      